<template>
  <div>
    <spinner :loading="processing" />
    <content-box icon="fa-user" title="Operadores">
      <toggable-box title="Seleção" v-model="filter_box_opened">
        <users-filter
        :filter_parms="usersFilter"
        @filter="filter_data"
        @cancel="close_filter" />
      </toggable-box>
      <top-action-buttons @search="toggle_filter_box" @add="new_user" />
      <div>
        <div class="show_on_pc">
          <table class="table">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th class="center">Status</th>
                <th class="right">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in getUsersWithActions" :key="user.id">
                <td>{{ user.name }}</td>
                <td>{{ user.email }}</td>
                <td
                  class="center"
                  v-html="$options.filters.status_active_inactive(user.status)"
                ></td>
                <td class="right">
                  <data-action
                    :actions="user.actions"
                    @edit_record="edit_user(user.id)"
                    @delete_record="delete_user(user.id)"
                    @block_record="block_user(user.id)"
                    @unblock_record="unblock_user(user.id)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <paginator :pages="usersFilter.pages" :current="usersFilter.page" @paginate="paginate" />
      </div>
    </content-box>
    <modal v-if="show_modal.update_user_data" :title="user_update_modal_title" size="md">
      <users-update :user_id="user_to_update_id" @cancel="show_modal.update_user_data = false" />
    </modal>
  </div>
</template>
<script type="text/javascript">
import ContentBox from '@/theme/boxes/ContentBox.vue';
import ToggableBox from '@/theme/boxes/ToggableBox.vue';
import DataAction from '@/theme/components/DataActions.vue';
import Paginator from '@/features/paginator/Paginator.vue';
import TopActionButtons from '@/theme/buttons/DataListActionButtons.vue';
import Modal from '@/theme/boxes/Modal.vue';

import Spinner from '@/features/Spinner/Spinner.vue';

import ShowHttpErrors from '@/mixins/ShowHttpErrors/showhttperrors';

import { mapActions, mapState, mapGetters } from 'vuex';
import UsersUpdate from './UsersUpdate.vue';
import UsersFilter from './UsersFilter.vue';

export default {
  components: {
    ContentBox,
    DataAction,
    Paginator,
    ToggableBox,
    TopActionButtons,
    Spinner,
    Modal,
    UsersFilter,
    UsersUpdate,
  },
  mixins: [ShowHttpErrors],
  data() {
    return {
      show_modal: {
        update_user_data: false,
      },
      user_to_update_id: '',
      data_actions: [
        {
          icon: 'fa-edit',
          text: 'Editar operador ',
          event: 'edit_record',
          color: 'orange',
        },
        {
          icon: 'fa-trash',
          text: 'Apagar operador',
          event: 'delete_record',
          color: 'red',
        },
      ],
      action_block: {
        icon: 'fa-ban',
        text: 'Bloquear acesso ',
        event: 'block_record',
        color: 'grey',
      },
      action_unblock: {
        icon: 'fa-check',
        text: 'Desbloquear acesso ',
        event: 'unblock_record',
        color: 'green',
      },
      filter_box_opened: false,
      processing: false,
    };
  },
  methods: {
    ...mapActions('operators', [
      'actionListUsers',
      'actionListUsersPaginate',
      'actionListUsersApplyFilter',
      'ActionDeleteUser',
      'ActionToggleBlockUser',
    ]),
    async paginate(page) {
      try {
        this.processing = true;
        this.actionListUsersPaginate({ page });
      } catch (error) {
        console.log(error);
      } finally {
        this.processing = false;
      }
    },
    new_user() {
      this.users_update('new');
    },
    edit_user(id) {
      this.users_update(id);
    },
    delete_user(id) {
      this.$dialogbox
        .confirm({
          title: 'Confirma a exclusão do usuário?',
          confirmButtonText: 'Apagar',
        })
        .then(async () => {
          try {
            this.processing = true;
            await this.ActionDeleteUser({ id });
            await this.actionListUsers();
            this.$dialogbox.success('Usuário excluído');
          } catch (error) {
            this.show_http_errors_on_dialogbox(error);
          } finally {
            this.processing = false;
          }
        });
    },
    block_user(id) {
      this.$dialogbox
        .confirm({
          title: 'Confirma o bloqueio do usuário?',
          confirmButtonText: 'Bloquear',
        })
        .then(async () => {
          try {
            this.processing = true;
            await this.ActionToggleBlockUser({ id });
            await this.actionListUsers();
            this.$dialogbox.success('Usuário bloqueado');
          } catch (error) {
            this.show_http_errors_on_dialogbox(error);
          } finally {
            this.processing = false;
          }
        });
    },
    async unblock_user(id) {
      try {
        this.processing = true;
        await this.ActionToggleBlockUser({ id });
        await this.actionListUsers();
        this.$dialogbox.success('Usuário desbloqueado');
      } catch (error) {
        this.show_http_errors_on_dialogbox(error);
      } finally {
        this.processing = false;
      }
    },
    users_update(id) {
      this.user_to_update_id = id;
      this.show_user_update_modal();
    },
    show_user_update_modal() {
      this.show_modal.update_user_data = true;
    },
    toggle_filter_box() {
      this.filter_box_opened = !this.filter_box_opened;
    },
    close_filter() {
      this.filter_box_opened = false;
    },
    async filter_data(filter) {
      try {
        this.processing = true;
        this.actionListUsersApplyFilter({ filter });
        this.close_filter();
      } catch (error) {
        console.log(error);
      } finally {
        this.processing = false;
      }
    },
  },
  computed: {
    ...mapState('operators', ['users', 'usersFilter']),
    ...mapGetters('operators', ['getUsersWithActions']),
    user_update_modal_title() {
      return this.user_to_update_id === 'new' ? 'Novo usuário' : 'Atualização de usuário';
    },
  },
  async mounted() {
    try {
      this.processing = true;
      await this.actionListUsers();
    } catch (error) {
      console.log(error);
    } finally {
      this.processing = false;
    }
  },
};
</script>
<style type="text/css"></style>
